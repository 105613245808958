<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="item.certificate_number"
                :label="$t('certificate.number')"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('certificate.number') }}
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="item.identification_number"
                :label="$t('certificate.identification-number')"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('certificate.identification-number') }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <DatePicker v-model="item.issue_date" :model-config="modelConfig">
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field
                    :value="inputValue"
                    :label="$t('certificate.issue-date')"
                    v-on="inputEvents"
                  >
                    <template
                      #label
                      v-if="action==action_items.create"
                    >
                      <span class="red--text"><strong>* </strong></span>{{ $t('certificate.issue-date') }}
                    </template>
                      </v-text-field>
                    </template>
              </DatePicker>
            </v-col>
            <v-col>
              <DatePicker v-model="item.expired_date" :model-config="modelConfig">
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field
                    :value="inputValue"
                    :label="$t('certificate.expired-date')"
                    v-on="inputEvents"
                  >
                    <template
                      #label
                      v-if="action==action_items.create"
                    >
                      <span class="red--text"><strong>* </strong></span>{{ $t('certificate.expired-date') }}
                    </template>
                  </v-text-field>
                </template>
              </DatePicker>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CommonAutocomplete
                v-model="customs_type"
                :label="$t('commodity.customs-type')"
                :placeholder="''"
                url="/commodity/customs-type/"
                :item_text="nameField"
                item_value="id"
                :query_field="nameFieldFilter"
                :extra_filter="item"
                :filter_fields="{}"
                :cache="[customs_type_cache]"
                :extra_condition="{
                  enable: true
                }"
                :required="true"
              ></CommonAutocomplete>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row v-if="action==action_items.create">
            <v-col>
              <v-textarea
                v-model="item.commoditys"
                :label="$t('certificate.commodity')"
                :hint="$t('certificate.hint')"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('certificate.commodity') }}
                </template>
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.certificate_url"
                :label="$t('certificate.certificate-url')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="disableSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { DatePicker } from 'v-calendar';
import BaseDialog from "./BaseDialog";
import CommonAutocomplete from "../filters/CommonAutocomplete";
export default {
  props: {
  },
  data: (vm) => ({
    topic: vm.$i18n.t("certificate.certificate"),
    masks: {
      input: 'YYYY-MM-DD',
      data: 'YYYY-MM-DD',
    },
    modelConfig: {
      type: 'string',
      mask: 'YYYY-MM-DD', // Uses 'iso' if missing
    },
    customs_type: null,
    customs_type_cache: null,
  }),
  components: {
    DatePicker,
    CommonAutocomplete
  },
  mixins: [BaseDialog],
  methods: {
    save(){
      let editedItem = this._.cloneDeep(this.item);
      editedItem.customs_type = this.customs_type
      this.$emit("onSave", editedItem);
    }
  },
  watch:{
    item(val,oldVal){
      if (this.action === this.action_items.edit) {
        if ("customs_type" in val && val.customs_type && typeof(val.customs_type)=='object') {
          this.customs_type = val.customs_type.id;
          this.customs_type_cache = val.customs_type;
        }
      }
    },
  }
};
</script>
