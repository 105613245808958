var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":_vm.max_width},on:{"click:outside":_vm.close,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('certificate.number')},scopedSlots:_vm._u([(_vm.action==_vm.action_items.create)?{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v(_vm._s(_vm.$t('certificate.number'))+" ")]},proxy:true}:null],null,true),model:{value:(_vm.item.certificate_number),callback:function ($$v) {_vm.$set(_vm.item, "certificate_number", $$v)},expression:"item.certificate_number"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('certificate.identification-number')},scopedSlots:_vm._u([(_vm.action==_vm.action_items.create)?{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v(_vm._s(_vm.$t('certificate.identification-number'))+" ")]},proxy:true}:null],null,true),model:{value:(_vm.item.identification_number),callback:function ($$v) {_vm.$set(_vm.item, "identification_number", $$v)},expression:"item.identification_number"}})],1)],1),_c('v-row',[_c('v-col',[_c('DatePicker',{attrs:{"model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({attrs:{"value":inputValue,"label":_vm.$t('certificate.issue-date')},scopedSlots:_vm._u([(_vm.action==_vm.action_items.create)?{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v(_vm._s(_vm.$t('certificate.issue-date'))+" ")]},proxy:true}:null],null,true)},inputEvents))]}}]),model:{value:(_vm.item.issue_date),callback:function ($$v) {_vm.$set(_vm.item, "issue_date", $$v)},expression:"item.issue_date"}})],1),_c('v-col',[_c('DatePicker',{attrs:{"model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({attrs:{"value":inputValue,"label":_vm.$t('certificate.expired-date')},scopedSlots:_vm._u([(_vm.action==_vm.action_items.create)?{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v(_vm._s(_vm.$t('certificate.expired-date'))+" ")]},proxy:true}:null],null,true)},inputEvents))]}}]),model:{value:(_vm.item.expired_date),callback:function ($$v) {_vm.$set(_vm.item, "expired_date", $$v)},expression:"item.expired_date"}})],1)],1),_c('v-row',[_c('v-col',[_c('CommonAutocomplete',{attrs:{"label":_vm.$t('commodity.customs-type'),"placeholder":'',"url":"/commodity/customs-type/","item_text":_vm.nameField,"item_value":"id","query_field":_vm.nameFieldFilter,"extra_filter":_vm.item,"filter_fields":{},"cache":[_vm.customs_type_cache],"extra_condition":{
                enable: true
              },"required":true},model:{value:(_vm.customs_type),callback:function ($$v) {_vm.customs_type=$$v},expression:"customs_type"}})],1),_c('v-col')],1),(_vm.action==_vm.action_items.create)?_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":_vm.$t('certificate.commodity'),"hint":_vm.$t('certificate.hint')},scopedSlots:_vm._u([(_vm.action==_vm.action_items.create)?{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v(_vm._s(_vm.$t('certificate.commodity'))+" ")]},proxy:true}:null],null,true),model:{value:(_vm.item.commoditys),callback:function ($$v) {_vm.$set(_vm.item, "commoditys", $$v)},expression:"item.commoditys"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('certificate.certificate-url')},model:{value:(_vm.item.certificate_url),callback:function ($$v) {_vm.$set(_vm.item, "certificate_url", $$v)},expression:"item.certificate_url"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-spacer'),(_vm.action != _vm.action_items.view)?_c('v-btn',{attrs:{"color":"primary","disabled":_vm.disableSave},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("save")))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }