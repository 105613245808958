<template>
  <v-container fluid>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <v-col class="text-right">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="createItem"
      >
        <v-icon>mdi-account-plus</v-icon>
        {{ $t("create") }}
      </v-btn>
    </v-col>
    <CertificateDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      v-on:onClose="onCloseEvent"
      v-on:onSave="onSaveEvent"
    ></CertificateDialog>
    <v-tabs>
      <v-tab @click="target_enable=true">{{ $t('enable') }}</v-tab>
      <v-tab @click="target_enable=false">{{ $t('disable') }}</v-tab>
    </v-tabs>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('certificate.list')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:item.customs_type="{ item }">
          {{ get_customs_type_name(item) }}
        </template>
        <template v-slot:item.certificate_url="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large 
                class="mr-2" 
                v-on="on" 
                @click="openUrl(item)"
                :disabled="!item.certificate_url"
                >mdi-earth</v-icon
              >
            </template>
            <span>{{ $t("go-website") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large 
                class="mr-2" 
                v-on="on" 
                @click="editItem(item)"
                :disabled="!item.enable"
                >mdi-pencil</v-icon
              >
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large 
                class="mr-2" 
                v-on="on" 
                @click="disableItem(item,$t('container.container'),item.container_number)"
                :disabled="!item.enable"
                >mdi-block-helper</v-icon
              >
            </template>
            <span>{{ $t("disable") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import CertificateDialog from "../dialogs/CertificateDialog"
import BaseTable from "./BaseTable.vue";
import { action_items } from "@/definition.js";
export default {
  data: vm => ({
    url: "/commodity/certificate/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
      },
      {
        sortable: true,
        text: vm.$i18n.t("certificate.number"),
        value: "certificate_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("certificate.identification-number"),
        value: "identification_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.customs-type"),
        value: "customs_type",
      },
      {
        sortable: true,
        text: vm.$i18n.t("certificate.issue-date"),
        value: "issue_date",
      },
      {
        sortable: true,
        text: vm.$i18n.t("certificate.expired-date"),
        value: "expired_date",
      },
      {
        sortable: true,
        text: vm.$i18n.t("certificate.certificate-url"),
        value: "certificate_url",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      }
    ],
    action_items: action_items,
    target_enable: true,
  }),
  mixins: [BaseTable],
  computed: {},
  components: {
    CertificateDialog,
    CommonAlertDialog
  },
  methods: {
    get_customs_type_name(item){
      return item.customs_type?this.getHanText(item.customs_type,"name"):""
    },
    // editItem(item){
    //   this.orig_dialogItem = this._.cloneDeep(item);
    //   this.dialogItem = this._.cloneDeep(item);
    //   this.action = action_items.edit;
    //   this.showDialog = true;
    // },
    openUrl(item){
      if (item.certificate_url) {
        window.open(item.certificate_url, "_blank");
      }
      
    },
  },
  watch: {
    target_enable(val) {
      this.getParams['enable'] = val
      this.getApi()
    }
  },
  mounted() {
    this.getParams['enable'] = true
  }
}
</script>
